var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-slider",class:{
    'slider-admin': _vm.admin_route,
    'slider-artworks': _vm.artworks_page || _vm.workshop_page,
    'slider-exh': _vm.exh_page,
    'slider-home': _vm.home_page,
    'slider-mob-hide': _vm.show_slider === false && _vm.home_page === false,
    'slider-mob-show': _vm.show_slider,
  }},[_c('div',[_c('i',{staticClass:"fas fa-chevron-left ctrl left",on:{"click":function($event){return _vm.prevImg()}}})]),_c('div',{staticClass:"img-content"},[(_vm.admin_route)?_c('div',{staticClass:"delete-img-div"},[_c('i',{staticClass:"far fa-trash-alt delete",on:{"click":function($event){return _vm.deleteImg()}}})]):_vm._e(),_c('div',{staticClass:"exit-div",class:{ 'show-exit': _vm.show_slider }},[_c('i',{staticClass:"fas fa-times exit",on:{"click":_vm.exitSlider}})]),_c('img',{directives:[{name:"hammer",rawName:"v-hammer:swipe.left.right",value:(_vm.swipeFunction),expression:"swipeFunction",arg:"swipe",modifiers:{"left":true,"right":true}}],class:{
        artwork_img: _vm.artworks_page || _vm.workshop_page,
        admin_img: _vm.admin_route,
        home_img: _vm.home_page,
        exh_img: _vm.exh_page,
      },attrs:{"src":_vm.image,"alt":""}}),_c('div',{staticClass:"num-imgs"},_vm._l((_vm.images),function(image,index){return _c('div',{key:'c' + index,staticClass:"circle",class:{'circle-active': image.active === true}})}),0)]),_c('div',[_c('i',{staticClass:"fas fa-chevron-right ctrl right",on:{"click":function($event){return _vm.nextImg()}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }